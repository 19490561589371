import React, { useEffect, useState } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import InvestmentPhilosophy from '../Utilities/InvestmentPhilosophy'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Testimonial from '../Utilities/Testimonial';
import NumberTicker from '../Utilities/NumberTicker';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Images ==========================================

import journey from "../Images/About us/journey.webp"
import vision from "../Images/About us/vision.webp"
import mission from "../Images/About us/mission.webp"
import AjaySir from "../Images/About us/Team/Ajay Yadav.webp"
import CharuPahuja from "../Images/About us/Team/Charu Pahuja.webp"
import ChetnaYadav from "../Images/About us/Team/Chetna Yadav.webp"
import ManojKumarGoel from "../Images/About us/Team/Manoj Kumar Goel.webp"
import NoopurSinghal from "../Images/About us/Team/Noopur Singhal.webp"
import SadhnaSinghal from "../Images/About us/Team/Sadhna Singhal.webp"

import WiseInvestments from "../Images/About us/Wise Group/1.webp"
import WiseWealth from "../Images/About us/Wise Group/3.webp"
import WiseInsuranceMarketing from "../Images/About us/Wise Group/2.webp"
import WiseFunds from "../Images/About us/Wise Group/4.webp"
import WiseBonds from "../Images/About us/Wise Group/5.webp"

// Images ends =====================================

const AboutUs = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [display, setDisplay] = useState("none");

    const news = [
        [
            {
                id: "news33",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1735299303/Wise%20FinServ%20Private%20Wealth/About%20us/News/Senior_Citizen_Event_2024_xhmofr.jpg",
                title: "Senior Citizen Event 2024",
                newspaperName: "",
                newspaperYear: "2024"
            },
            {
                id: "news32",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1735299303/Wise%20FinServ%20Private%20Wealth/About%20us/News/Investment_Awarness_Program_Cleo_County_Sector_121_Noida_d0j9ko.jpg",
                title: "Investment Awarness Program Cleo County Sector 121 Noida",
                newspaperName: "Hindustan Times",
                newspaperYear: "2024"
            },
            {
                id: "news1",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698472631/Wise%20FinServ%20Private%20Wealth/About%20us/News/WhatsApp_Image_2023-10-08_at_22.07.30_a6898c5e_yh4vtm.jpg",
                title: "To Protect Liabilities, A term Plan is a better option",
                newspaperName: "times of india",
                newspaperYear: "2019"
            },
            {
                id: "news2",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698472838/Wise%20FinServ%20Private%20Wealth/About%20us/News/WhatsApp_Image_2023-10-08_at_21.41.36_fd5969bb_bdsgtg.jpg",
                title: "Charu pahuja: Leading the future of client-centric business model",
                newspaperName: "silicon india",
                newspaperYear: "2017"
            },
            {
                id: "news3",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698475754/Wise%20FinServ%20Private%20Wealth/About%20us/News/IMG-20231009-WA0011_ksvkit.jpg",
                title: "वाइस फिंसर्व का वाराणसी में खुला निःशुल्क वित्तीय परामर्श केन्द्र",
                newspaperName: "Rashtriya Sahara",
                newspaperYear: "2018"
            }
        ],
        [
            {
                id: "news4",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696657275/Wise%20FinServ%20Private%20Wealth/About%20us/News/mint-newspaper-2018_fjbh6g.jpg",
                title: "Ajay Yadav",
                newspaperName: "mint newspaper",
                newspaperYear: "2018"
            },
            {
                id: "news5",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696657274/Wise%20FinServ%20Private%20Wealth/About%20us/News/jvcc_event_2017_dt1d00.jpg",
                title: "jal vayu Vihar residents get tips on best investment options",
                newspaperName: "JVCC event",
                newspaperYear: "2017"
            },
            {
                id: "news6",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698475943/Wise%20FinServ%20Private%20Wealth/About%20us/News/WhatsApp_Image_2023-10-08_at_21.49.06_e787a664_piclzo.jpg",
                title: "प्रामाणिक फाइनेंसियल एडवाइजर की सलाह से करें निवेश : अजय",
                newspaperName: "Rashtriya Sahara",
                newspaperYear: "2018"
            },
        ],
        [
            {
                id: "news7",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696657274/Wise%20FinServ%20Private%20Wealth/About%20us/News/jvcc_event_2017_t93sip.jpg",
                title: "curius resident learn more about investments: senior citizens of Jal Vayu",
                newspaperName: "JVCC event",
                newspaperYear: "2017"
            },
            {
                id: "news8",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696657274/Wise%20FinServ%20Private%20Wealth/About%20us/News/jvcc_event_-_2017_cg5pqc.jpg",
                title: "investment 101 at jal vayu vihar, noida",
                newspaperName: "JVCC event",
                newspaperYear: "2017"
            },
            {
                id: "news9",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698472739/Wise%20FinServ%20Private%20Wealth/About%20us/News/WhatsApp_Image_2023-10-08_at_21.44.38_f5e885b6_xkvzuq.jpg",
                title: "a reader asked why is past performance nt indicative of future performance",
                newspaperName: "hindustan times",
                newspaperYear: "2018"
            },
        ],
        [
            {
                id: "news10",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696657273/Wise%20FinServ%20Private%20Wealth/About%20us/News/hindustan_times_2018_ehczid.jpg",
                title: "a story of investment",
                newspaperName: "hindustan times",
                newspaperYear: "2018"
            },
            {
                id: "news11",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696657273/Wise%20FinServ%20Private%20Wealth/About%20us/News/hindustan_times_2018_omlctg.jpg",
                title: "a reader asked: 'Can a fund's risk change over time?'",
                newspaperName: "hindustan times",
                newspaperYear: "2018"
            },
            {
                id: "news12",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696657273/Wise%20FinServ%20Private%20Wealth/About%20us/News/hindustan_times_2018._vflzhx.jpg",
                title: "Guru Speak",
                newspaperName: "hindustan times",
                newspaperYear: "2018"
            },
        ],
        [
            {
                id: "news13",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696657272/Wise%20FinServ%20Private%20Wealth/About%20us/News/hindustan_times_-_2018_pkssog.jpg",
                title: "Guru Speak",
                newspaperName: "hindustan times",
                newspaperYear: "2018"
            },
            {
                id: "news14",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696657272/Wise%20FinServ%20Private%20Wealth/About%20us/News/hindustan_times_-_2017_vd3x8n.jpg",
                title: "वरिष्ठ नागरिकों को दी निवेश संबंधी जानकारी",
                newspaperName: "hindustan times",
                newspaperYear: "2018"
            },
            {
                id: "news15",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696657272/Wise%20FinServ%20Private%20Wealth/About%20us/News/dainik_jvcc_-_2017_ujx5ya.jpg",
                title: "लोगों ने किया लाफ्टर एक्सरसाइज",
                newspaperName: "dainik jvcc",
                newspaperYear: "2017"
            },
        ],
        [
            {
                id: "news16",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696657272/Wise%20FinServ%20Private%20Wealth/About%20us/News/dainik_jagran_-2017_bqb7vo.jpg",
                title: "जेवीसीसी में लोगों को बताए गए बेहतर निवेश के तरीके",
                newspaperName: "dainik jagran",
                newspaperYear: "2017"
            },
            {
                id: "news17",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698475836/Wise%20FinServ%20Private%20Wealth/About%20us/News/IMG-20231009-WA0014_iv3rz5.jpg",
                title: "वाइस फिंसर्व की वित्तीय सेवाएं अब शहर में",
                newspaperName: "amr ujala",
                newspaperYear: "2018"
            },
            {
                id: "news18",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698475330/Wise%20FinServ%20Private%20Wealth/About%20us/News/WhatsApp_Image_2023-10-08_at_21.59.38_201f1222_gmkwul.jpg",
                title: "Asset Allocation is the Most Important Parameter in Backing the Overall Protfolio Return",
                newspaperName: "hindustan times",
                newspaperYear: "2018"
            },
        ],
        [
            {
                id: "news19",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698476325/Wise%20FinServ%20Private%20Wealth/About%20us/News/1_l1s9u1.jpg",
                title: "चिकित्सकों को निवेश के गुर सिखाए",
                newspaperName: "Hindustan Times",
                newspaperYear: "2021"
            },
            {
                id: "news20",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698476326/Wise%20FinServ%20Private%20Wealth/About%20us/News/2_qdcbfo.jpg",
                title: "सेक्टर-55 में कार्यक्रम का शुभारंभ करतीं मुख्य अतिथि।",
                newspaperName: "",
                newspaperYear: ""
            },
            {
                id: "news21",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698476369/Wise%20FinServ%20Private%20Wealth/About%20us/News/3_dngqgq.jpg",
                title: "सरकार व कंपनियों के लिए उधार लेने का जरिया है बांड",
                newspaperName: "Jagran",
                newspaperYear: "2022"
            },
        ],
        [
            {
                id: "news22",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698476369/Wise%20FinServ%20Private%20Wealth/About%20us/News/4_h94umq.jpg",
                title: "आईएमए भवन में चिकित्सकों को दी निवेश की जानकारी",
                newspaperName: "",
                newspaperYear: "",

            },
            {
                id: "news23",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699599684/Wise%20FinServ%20Private%20Wealth/About%20us/News/5_t5y17l.jpg",
                title: "म्युचुअल फड में निवेश एक बेहतर विकल्प",
                newspaperName: "Hindustan",
                newspaperYear: "2021",

            },
            {
                id: "news24",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699505392/Wise%20FinServ%20Private%20Wealth/About%20us/News/Hindustan_dhtwqa.jpg",
                title: "आईएमए डॉक्टरों ने समझे निवेश के गुर",
                newspaperName: "Hindustan",
                newspaperYear: "",

            },
        ],
        [
            {
                id: "news25",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699349580/Wise%20FinServ%20Private%20Wealth/About%20us/News/Amarujala_My_City_pg_no_2_kbtgsf.jpg",
                title: "आईएमए भवन में चिकित्सकों को दी निवेश की जानकारी",
                newspaperName: "Amarujala My City",
                newspaperYear: "",

            },
            {
                id: "news26",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699349580/Wise%20FinServ%20Private%20Wealth/About%20us/News/WhatsApp_Image_2023-11-03_at_16.30.39_897aa336_jyi5ra.jpg",
                title: "चिकित्सकों को निवेश की दी जानकारी",
                newspaperName: "Hindustan Times",
                newspaperYear: "2023",

            },
            {
                id: "news27",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705557881/Wise%20FinServ%20Private%20Wealth/About%20us/News/7_aqm5mt.jpg",
                title: "सेमिनार में 100 से अधिक डॉक्टरों ने लिया भाग",
                newspaperName: "Desh Pratidin",
                newspaperYear: "2024",

            },
        ],
        [
            {
                id: "news28",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705557882/Wise%20FinServ%20Private%20Wealth/About%20us/News/8_obxy5d.jpg",
                title: "आई एम ए सदस्यों ने समझा वेल्थ मैनेजमेंट के लाभ",
                newspaperName: "Desh Pratidin",
                newspaperYear: "2024",
                url: "https://youtu.be/ybNLYMsiurg?feature=shared"

            },
            {
                id: "news29",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705557881/Wise%20FinServ%20Private%20Wealth/About%20us/News/9_mdmb8h.jpg",
                title: "निवेश करने से पहले सही सलाह लेना जरूरी: अजय",
                newspaperName: "Desh Pratidin",
                newspaperYear: "2024",

            },
            {
                id: "news30",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705557880/Wise%20FinServ%20Private%20Wealth/About%20us/News/6_tvbou5.jpg",
                title: "प्राइवेट वेल्थ निवेशकों को किया जागरूक",
                newspaperName: "Rashtriya Swaroop",
                newspaperYear: "2024",

            },
        ],
        [
            {
                id: "news31",
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705743987/Wise%20FinServ%20Private%20Wealth/About%20us/News/9_os8jc9.jpg",
                title: "वाईज फिनसर्व की शाखा का उद्घाटन",
                newspaperName: "Desh Pratidin",
                newspaperYear: "2024",
                url: "https://youtu.be/-GhK58Wld1Y",
            }
        ]

    ]

    const wisegroup = [
        {
            url: "https://www.wiseinvestments.in/",
            imgUrl: WiseInvestments,
            alt: "Wise Investments"
        },
        {
            url: "",
            imgUrl: WiseWealth,
            alt: "Wise Wealth"
        },
        {
            url: "http://wisefinservinsurance.com",
            imgUrl: WiseInsuranceMarketing,
            alt: "Wise Insurenace marketing"
        },
        {
            url: "https://wisefunds.in/",
            imgUrl: WiseFunds,
            alt: "Wise funds"
        },
        {
            url: "https://wisebonds.in/",
            imgUrl: WiseBonds,
            alt: "Wise Bonds"
        }

    ]

    const team = [
        {
            id: "team1",
            imgUrl: AjaySir,
            name: "Mr. Ajay Yadav",
            designation: "CEO & CIO",
            description: <p>Mr. Ajay Kumar Yadav CFP CM, the Group CEO & CIO of Wise Finserv, a visionary leader who is the driving force behind the well-oiled machinery that powers this esteemed financial institution across India. With over 23+ years of diverse experience in both the Financial Services and Pharmaceutical Industry, Mr. Yadav is a true master of his craft.
                <br /><br />
                As a Chartered Member of the Financial Planning Standards Board, Mr .Yadav holds one of the most highly recognized certifications in Wealth Management & Financial Planning, a testament to his unwavering dedication to his profession. He completed his MBA from Madras University and has been at the helm for more than two decades, leading by example and inspiring those around him to achieve excellence.<br /><br />

                Mr. Yadav's commitment to continued learning and professional growth is evident in his attendance of a short course on Behavioral Finance from IIM Ahmedabad, one of India's premier B Schools. He possesses a comprehensive understanding of diverse facets of Financial Services, including Private Banking and Investment Banking. He is a talented Wealth Manager with an exceptional understanding of Financial Planning, having developed long-standing relationships with high-net-worth individuals across the globe.<br /><br />

                Before joining Wise Finserv, Mr. Yadav served with distinction at Torrent Pharma, Reliance Capital, ICICI Bank, DBS Bank & ICICI Securities Private Wealth. He has been recognized by various media houses of repute, including Hindustan Times, Radio One, Amar Ujala, Jagran, and Dalal Street, among others.</p>,
            linkedin: "https://www.linkedin.com/in/ajay-kumar-yadav-137b5721/"
        },
        {
            id: "team2",
            imgUrl: CharuPahuja,
            name: "Charu pahuja",
            designation: "Director & Chief Operating Officer",
            description: <p>Ms. Charu Pahuja CFP CM , the Group Director Business Head of Wise Finserv, a dynamic and accomplished executive leader who has played an instrumental role in driving the company's brand and establishing a strong domestic footprint of growth pan India With over 20+ years of illustrious experience across the Banking & Financial Services Industry, Ms. Pahuja is a true trailblazer in her field.
                <br /><br />
                As a Chartered Member of the Financial Planning Standards Board, Ms. Pahuja holds one of the most highly recognized certifications in Wealth Management & Financial Planning, a testament to her unwavering commitment to excellence. She completed her MBA in Finance with the highest honors and has also attended a short course on Behavioral Finance from IIM Ahmedabad, one of India's premier B Schools, demonstrating her commitment to continued learning and professional growth.
                <br /><br />
                Ms. Pahuja is an established executive leader who has executed corporate governance standards to align the group companies' expanding business and equip a sustainable workforce. She is a Wealth Manager of the highest standards with an exceptional understanding of Financial Planning, having developed long-standing relationships with high-net-worth individuals across the globe.
                <br /><br />

                Before joining Wise Finserv, Ms. Pahuja served with distinction at HDFC Bank, ICICI Bank, DBS Bank & ICICI Securities Private Wealth, where she honed her skills and established a reputation for excellence in her field. She has been recognized by various reputed media houses, including Hindustan Times, Radio One, Amar Ujala, Jagran, and Dalal Street, among others.</p>,
            linkedin: "https://www.linkedin.com/in/charu-pahuja-3406aa43/"
        },
        {
            id: "team3",
            imgUrl: ChetnaYadav,
            name: "chetna yadav",
            designation: "director & principal officer",
            description: <p>Ms. Chetna Yadav has experience of more than a decade in Customer Relationship Management, Client Service, Operations, and Operational Risk Management.<br /><br />

                Prior to joining Wise Finserv, she was associated with Panacea Biotec, EXL Services Pvt. Ltd., Steria India Ltd., & Ascent Construction Pvt. Ltd.<br /><br />

                She is a MBA from University of Allahabad.</p>,
            linkedin: "https://www.linkedin.com/in/chetna-yadav-00342210a/"
        },
        {
            id: "team4",
            imgUrl: SadhnaSinghal,
            name: "sadhna singhal",
            designation: "head- human resources",
            description: <p>Ms. Sadhna Singhal has over 16 years of experience in Talent Acquisition, Talent Management and Employee Engagement.<br /><br />

                Prior to joining Wise Finserv, She was associated with ANSA Co-operative and Singhal & Singhal Fabricators Pvt. Ltd.<br /><br />

                She is a Bachelor in Science & M.A from University of Kanpur.</p>,
            linkedin: "https://www.linkedin.com/in/sadhana-singhal-98335a20/"
        },
        {
            id: "team5",
            imgUrl: NoopurSinghal,
            name: "noopur singhal",
            designation: "chief legal advisor",
            description: <p>Noopur Singhal, Advocate, LL.B. from Campus Law Centre, University of Delhi, is our associate partner and has been on the company legal advisory panel. Ms. Noopur is a B.A(P) Graduate from Delhi University and has Certificate course in Forensic Science. She started her practice in the year 2007 in all kinds of cases i.e. Civil, Criminal, Revenue, Service, Land and Rent Laws and is the chairperson of Local Complaints Committee, New Delhi District, New Delhi since 2016.<br /><br />

                She has experience of more than 10 years in litigation side and is handling all kinds of litigations in Hon’ble Supreme Court of India, High Court of Delhi and other Tribunals and Courts.<br /><br />

                She has successfully conducted litigations in the field of Writ Law (Civil and Criminal), Constitutional Law, Service Law, Arbitration and Dispute Resolutions, Land Acquisition Law, Tort Law, Labour Law, Employment Law, Banking and Matrimonial Law. She has a vast experience in handling legal matters on behalf of State Govt., Public Sector Undertakings and Mega Corporations and Companies and has expertise in providing legal opinions, handling contractual matters, holding legal conferences to address the legal issues of individuals, state corporations, partnership firms and private limited companies.</p>,
            linkedin: "https://www.linkedin.com/in/noopur-singhal-0ba06a23/"
        },
        {
            id: "team6",
            imgUrl: ManojKumarGoel,
            name: "Manoj Kumar Goel",
            designation: "chief advsior-taxation",
            description: <p>Mr. Manoj Kumar Goel is a Law Graduates & Member of Bar Council of Delhi. He is practicing since 1995. He has rich experience (more than 27 Years) in the field of Direct / Indirect Taxation, Corporate Advisory Services and other services.</p>,
            linkedin: ""
        },

    ]

    const roadmap = [
        {
            year: "2010",
            title: "Wise Investments",
            description: "Wise Investments serves family offices and Ultra High Net Worth Individuals (UHNI). Our expertise lies in investment management, debt syndication facilitation, and raising capital through private equity avenues. Additionally, we excel in the distribution of structured financial products, Portfolio Management Services (PMS), Real Estate funds, and Alternative Investment Funds (AIF)."
        },
        {
            year: "2013",
            title: "Wise Finserv",
            description: "Wise Finserv Pvt Ltd was founded with the initial aim of encouraging financial planning among retail investors. Following our significant achievements, we have expanded our services to encompass Private Wealth management, focusing on the needs of High Net Worth Individuals (HNI) and effectively bridging the gap between retail and family offices."
        },
        {
            year: "2014",
            title: "Wise Finserv Advisory",
            description: " Wise Finserv (Advisory) was established and obtained a license from SEBI to offer investment advisory services."
        },
        {
            year: "2016",
            title: "Wise Finserv Insurance",
            description: " Wise Finserv Insurance, was established to enhance our product offerings by including insurance products in our recommendations. Within Wise Finserv Insurance, we provide recommendations for both life insurance and health insurance."
        },
        {
            year: "2020",
            title: "Wise Funds",
            description: "Wise Funds, a digital venture by Wise Finserv, caters to the rising trend of online investing. Our fully paperless system breaks geographical barriers, offering convenient, location-independent transactions. Investors can easily buy and sell mutual funds, and diversify into stocks and bonds, all while monitoring investments 24/7."
        },
        {
            year: "2021",
            title: "Wisebonds.in",
            description: "Wisebonds.in, the platform, was introduced to enable retail and High Net Worth Individuals (HNI) investors to purchase bonds online from a wide selection of hundreds of available bond options."
        },
    ]

    return (
        <div>
            <Header />
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="canonical" href="https://www.wisefinserv.com/about-us" />
            </Helmet>
            <div className="about-page">
                <div className="page-title">
                    <h4>The journey of Wise Finserv Group </h4>
                </div>

                {/* journey */}
                <div className="journey">
                    <div className="journey-img">
                        <img src={journey} alt="journey" />
                    </div>
                    <div className="journey-text">
                        <p>The formation of Wise Finserv was inspired by a growing trend in the corporate world, where many organizations prioritized a product-centric approach to meet their internal goals rather than a client-centric approach to fulfill the financial aspirations of their clients.</p>
                        <p>The journey of financial planning and wealth management services at Wise Finserv began when two like-minded, seasoned, and highly qualified wealth managers, <span>Ajay Kumar Yadav</span> and <span>Charu Pahuja</span>, joined forces with a visionary goal. Their vision was to revolutionize financial planning services across the nation by harnessing the power of technology and establishing exceptionally high standards in the practice of financial planning.</p>
                        <p>   Their objective extended beyond mere company growth; it encompassed a profound sense of social responsibility. They aimed not only to expand the organization's balance sheet but also to disseminate the principles of sound financial planning far and wide.</p>
                        <p>  Today, Wise Finserv Group stands tall as a leading diversified financial services company in India, specializing in investments, financial planning, and private wealth management. With a substantial client base, we build long-term relationships and offer niche investment products. Our strategic partnerships provide holistic services globally. Trusted as wealth-building partners, we constantly exceed client expectations. We have offices in Mumbai, Delhi, Noida, Lucknow, Varanasi.</p>
                        <p>   We offer simple investment options tailored to clients' needs, serving HNI and UHNI. Market-leading qualifications assure clients that their future is in capable hands. Our comprehensive services cover personal finance globally. Our innovative approach and strategic partnerships ensure exceptional client experiences. Services include investment advisory, portfolio management, tax planning, insurance, and retirement planning.</p>
                        <p>  At Wise Finserv, we prioritize technology and innovation, utilizing digital platforms for efficiency and real-time market insights. We measure success by our clients' achievements and financial security. Upholding integrity and transparency, we empower clients to make informed decisions. With a client-centric focus, experienced professionals, and cutting-edge tools, we guide clients toward a secure future.
                        </p>
                    </div>

                </div>
                {/* journey ends */}

                {/* roadmap */}
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="main-timeline">
                                {roadmap.map((item) => (
                                    <div class="timeline">
                                        <div class="timeline-icon">{item.year}</div>
                                        <a href="#" class="timeline-content">
                                            <h3 class="title">{item.title}</h3>
                                            <p class="description">{item.description}</p>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {/* roadmap ends */}

                {/* vision & mission */}
                <div className="vision-and-mission">
                    <div className="vision">
                        <img src={vision} alt="vision" />
                        <div className="caption">
                            <h5>Our Vision</h5>
                            <p>At Wise Finserv, our vision is to empower individuals and organizations to achieve financial well-being and prosperity. We aspire to be the foremost provider of client-centric wealth management services, setting new standards of excellence in the financial industry.</p>
                        </div>

                    </div>
                    <div className="mission">
                        <img src={mission} alt="mission" />
                        <div className="caption">
                            <h5>Our Mission</h5>
                            <p>Our mission is simple yet profound: to be a trusted partner to each of our customers, guiding them on their path to achieving their financial goals through impartial and unbiased financial advice.</p>
                        </div>

                    </div>
                </div>
                {/* vision & mission ends */}

                {/* investment philosophy */}
                <InvestmentPhilosophy />
                {/* investment philosophy ends */}

                {/* number-ticker */}
                <NumberTicker />
                {/* number-ticker ends */}

                {/* Team */}
                <div className="team">
                    <div className="page-title">
                        <h4>Team</h4>
                    </div>
                    <div className="team-body">
                        {team.map((item) => (
                            <div className="team-card">

                                <img src={item.imgUrl} alt={item.name} />
                                <div className="team-caption">
                                    <h5>{item.name}</h5>
                                    <p>{item.designation}</p>
                                </div>
                                <button data-bs-toggle="modal" data-bs-target={"#" + item.id}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                                </button>
                            </div>
                        ))}

                    </div>
                    <div className="team-description">
                        {team.map((item) => (
                            <div class="modal fade  " id={item.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered ">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h3 class="modal-title fs-5" id="staticBackdropLabel">{item.name}<br /><span>{item.designation}</span></h3>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            {item.description}
                                            <a href={item.linkedin}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" /></svg></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
                {/* Team ends */}

                {/* testimonial */}
                <Testimonial />
                {/* testimonial ends */}

                {/* awards */}
                {/* <div className="awards">
                    <div className="page-title">
                        <h4>Awards & Recognition</h4>
                    </div>
                    <div className="carousel">
                        <div class="carousel-wrapper">
                            <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false} interval={4000}>
                                <div className='awards-body'>
                                    <div className="awards-card">
                                        <img src={reliance} alt="" />
                                        <h4>SIP Samrat PARP 2017 – 18</h4>
                                    </div>
                                    <div className="awards-card">
                                        <img src={max} alt="" />
                                        <h4>Is proud to recognize our diamond member for financial year in 2017-18 </h4>
                                    </div>

                                    <div className="awards-card">
                                        <img src={adityabirla} alt="" />
                                        <h4>Certificate of Appreciation  for valuable  contribution In strategy meet JFM’18 in 2017-18</h4>
                                    </div>
                                </div>
                                <div className='awards-body'>
                                    <div className="awards-card">
                                        <img src={reliance} alt="" />
                                        <h4>Super Achiever Club PARP 2017 – 18</h4>
                                    </div>
                                    <div className="awards-card">
                                        <img src={birla} alt="" />
                                        <h4>Top SIP Contributors in 2016-17</h4>
                                    </div>

                                    <div className="awards-card">
                                        <img src={icici} alt="" />
                                        <h4>For Valuable Contribution</h4>
                                    </div>
                                </div>
                                <div className='awards-body'>
                                    <div className="awards-card">
                                        <img src={birla} alt="" />
                                        <h4>For the Outstanding contribution in BSL Medium Term plan in financial year 2013-14</h4>
                                    </div>
                                    <div className="awards-card">
                                        <img src={franklin} alt="" />
                                        <h4>Outstanding contribution for the year 2013-14</h4>
                                    </div>

                                    <div className="awards-card">
                                        <img src={hdfc} alt="" />
                                        <h4>Outstanding contribution in mobilising deposits of Rs. 182.50 lac during the financial year 2010-2011</h4>
                                    </div>
                                </div>
                                <div className='awards-body'>
                                    <div className="awards-card">
                                        <img src={dhfl} alt="" />
                                        <h4>Outstanding Contribution towards DHFL Fixed deposits business for FY 2015-2016</h4>
                                    </div>
                                    <div className="awards-card">
                                        <img src={aditya_birla} alt="" />
                                        <h4>Gold Member for Valued contribution towards mutual fund FY 2013-14 </h4>
                                    </div>

                                    <div className="awards-card">
                                        <img src={aditya_birla} alt="" />
                                        <h4>Gold Plus member for the financial year 2015-16</h4>
                                    </div>
                                </div>
                                <div className='awards-body'>
                                    <div className="awards-card">
                                        <img src={max} alt="" />
                                        <h4>For valuable contribution in Glittery Goa strategy meet OND'17</h4>
                                    </div>
                                    <div className="awards-card">
                                        <img src={max} alt="" />
                                        <h4>Wise Finserv signature club champs</h4>
                                    </div>

                                    <div className="awards-card">
                                        <img src={motilal} alt="" />
                                        <h4>For outstanding support in Motilal Oswal</h4>
                                    </div>
                                </div>
                                <div className='awards-body'>
                                    <div className="awards-card">
                                        <img src={dhfl} alt="" />
                                        <h4>Valuable contribution to the DHFL fixed deposits business </h4>
                                    </div>
                                    <div className="awards-card">
                                        <img src={aditya_birla} alt="" />
                                        <h4>Gold member for the financial year 2014-15</h4>
                                    </div>

                                    <div className="awards-card">
                                        <img src={icici} alt="" />
                                        <h4>Chairman’s Circle 2015</h4>
                                    </div>
                                </div>
                                <div className='awards-body'>
                                    <div className="awards-card">
                                        <img src={icici} alt="" />
                                        <h4>Chairman’s Circle</h4>
                                    </div>
                                    <div className="awards-card">
                                        <img src={max} alt="" />
                                        <h4>For leading launch of IMF Channel, IMF conclave H1 FY 17-18</h4>
                                    </div>

                                    <div className="awards-card">
                                        <img src={shriram} alt="" />
                                        <h4>For achieving 5 star club under star club 2011</h4>                                    </div>
                                </div>
                                <div className='awards-body'>
                                    <div className="awards-card">
                                        <img src={hdfcMutual} alt="" />
                                        <h4>Winner noble white upper crust awards 2016-17</h4>
                                    </div>
                                    <div className="awards-card">
                                        <img src={shriram} alt="" />
                                        <h4>Certificate of appreciation for qualifying 3 star club in 2015</h4>
                                    </div>

                                    <div className="awards-card">
                                        <img src={icici} alt="" />
                                        <h4>10,000 crore of AUM</h4>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div> */}
                {/* awards ends */}

                {/* media presence */}
                <div className="media-presence">
                    <div className="page-title">
                        <h4>Our Media Presence </h4>
                    </div>

                    <div className="carousel">
                        <div class="carousel-wrapper">
                            <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false} interval={4000} swipeable={false}>
                                {news.map((item) => (
                                    <div className='media-body'>
                                        {item.map((datas) => (
                                            <div data-bs-toggle="modal" data-bs-target={"#" + datas.id} className="media-card">
                                                <a>{datas.title}</a>
                                                <div className="media-caption">
                                                    <h4>{datas.newspaperName}</h4>
                                                    <p>{datas.newspaperYear}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    {news.map((item) => (
                        <div className="lightbox">
                            {item.map((datas) => (
                                <div class="modal fade  " id={datas.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            <a target="_blank" href={datas.url}> <img src={datas.imgUrl} alt={datas.title} /></a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}

                </div>
                {/* media presence ends */}

                {/* the wise group */}
                <div className="wise-group">
                    <div className="page-title">
                        <h4>the wise group</h4>
                    </div>

                    <div className="wise-group-body">
                        {wisegroup.map((item) => (
                            <Link to={item.url} target='_blank'><img src={item.imgUrl} alt={item.alt} /></Link>
                        ))}
                    </div>


                </div>
                {/* the wise group ends*/}
                <p className='imp-line'>Wise FinServ is, inter alia, an AMFI registered mutual fund distributor (AMFI registration no 106923) which primarily provides mutual fund distribution services and in addition presents various investment opportunities to its clients and potential clients.</p>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs
