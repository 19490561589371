import React, { useState, useEffect, useRef } from 'react'
import emailjs from '@emailjs/browser';
import fb from "../../AdminPanel/Blog/firebase";
import { getDownloadURL, ref as storageRef, uploadBytes } from "firebase/storage";
import { v4 as uuid } from "uuid";
import { ToastContainer, toast } from 'react-toastify';
const DB = fb.firestore()
const OFPList = DB.collection('Opportunities For Professionals');
const storage = fb.storage();
const JFFP = DB.collection('Job Form Professionals');
const JobFormForProfessionals = () => {

    const form = useRef();

    const submit = async (e) => {
        e.preventDefault();
        if (Name == "" && pdfUpload == "" && Email == "" && Mobile == "" && Position == "" && CoverLetter == "" && Location == "") {
            alert("Please fill all the fields");
        } else if (Name == "") {
            alert("Please fill Job Title");
        } else if (Email == "") {
            alert("Please fill Email");
        } else if (Mobile == "") {
            alert("Please fill Mobile");
        } else if (Location == null) {
            alert("Please Select Preferred Location");
        } else if (Position == null) {
            alert("Please Select Job Position");
        } else if (CoverLetter == "") {
            alert("Please write a Cover Letter");
        }
        else if (pdfUpload === null) {
            alert("Please upload CV");
        }
        else {

            if (pdfUpload.size > 100000) {
               alert("Please upload a file smaller than 1mb");
                return false;
            }
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(Email)) {
                alert('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(Mobile)) {
                    alert("Please enter only number.");
                    return

                } else if (Mobile.length !== 10) {
                    alert("Please enter 10 digit phone number.");
                    return
                }
            }

            uploadBytes(pdfRef, pdfUpload)
                .then((snapshot) => {
                    getDownloadURL(snapshot.ref)
                        .then((url) => {
                            setpdfURL(url);
                        })
                        .catch((error) => {
                            alert(error.message);
                        });
                })
                .catch((error) => {
                    alert(error.message);
                });

            JFFP.add({
                Name: Name,
                Email: Email,
                Mobile: Mobile,
                CV: pdfURL,
                Position: Position,
                Location: Location,
                Cover_Letter: CoverLetter
            }).then((docRef) => {
                alert('Application Submitted Successfully')
            }).catch((error) => {
                console.error("error:", error);
            });

            emailjs.sendForm('service_txl1qnq', 'template_5plugq8', form.current, '5zJL2C7TKJiff0wdi')
                .then((result) => {
                    console.log(result.text);

                }, (error) => {
                    console.log(error.text);
                });
        }
    }

    const [Name, SetName] = useState("");
    const [Email, SetEmail] = useState("");
    const [Mobile, SetMobile] = useState("");
    const [Position, SetPosition] = useState("");
    const [Location, SetLocation] = useState("");
    const [CoverLetter, SetCoverLetter] = useState("");
    const [careerOFP, SetCareerOFP] = useState([]);
    const [pdfUpload, setPdfUpload] = useState(null);//uploading image
    const uniqueId = uuid();//creating a unique id
    const pdfRef = storageRef(storage, `${uniqueId}`);//storing image in firebase storage with a unique id
    const [pdfURL, setpdfURL] = useState('');// retrieving URL

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        // fetching data from database for career for students
        const OFP = OFPList.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            SetCareerOFP(data);
        });

        // Detach listener
        return OFP;
        // fetching data from database for career ends
    }, []);


    // const city = [
    //     { value: "Adilabad", label: "Adilabad" },
    //     { value: "Agra", label: "Agra" },
    //     { value: "Ahmedabad", label: "Ahmedabad" },
    //     { value: "Ahmednagar", label: "Ahmednagar" },
    //     { value: "Aizawl", label: "Aizawl" },
    //     { value: "Ajitgarh(Mohali)", label: "Ajitgarh (Mohali)" },
    //     { value: "Ajmer", label: "Ajmer" },
    //     { value: "Akola", label: "Akola" },
    //     { value: "Alappuzha", label: "Alappuzha" },
    //     { value: "Aligarh", label: "Aligarh" },
    //     { value: "Alirajpur", label: "Alirajpur" },
    //     { value: "Allahabad", label: "Allahabad" },
    //     { value: "Almora", label: "Almora" },
    //     { value: "Alwar", label: "Alwar" },
    //     { value: "Ambala", label: "Ambala" },
    //     { value: "AmbedkarNagar", label: "Ambedkar Nagar" },
    //     { value: "Amravati", label: "Amravati" },
    //     { value: "Amrelidistrict", label: "Amreli district" },
    //     { value: "Amritsar", label: "Amritsar" },
    //     { value: "Anand", label: "Anand" },
    //     { value: "Anantapur", label: "Anantapur" },
    //     { value: "Anantnag", label: "Anantnag" },
    //     { value: "Angul", label: "Angul" },
    //     { value: "Anjaw", label: "Anjaw" },
    //     { value: "Anuppur", label: "Anuppur" },
    //     { value: "Araria", label: "Araria" },
    //     { value: "Ariyalur", label: "Ariyalur" },
    //     { value: "Arwal", label: "Arwal" },
    //     { value: "AshokNagar", label: "Ashok Nagar" },
    //     { value: "Auraiya", label: "Auraiya" },
    //     { value: "Aurangabad", label: "Aurangabad" },
    //     { value: "Azamgarh", label: "Azamgarh" },
    //     { value: "Badgam", label: "Badgam" },
    //     { value: "Bagalkot", label: "Bagalkot" },
    //     { value: "Bageshwar", label: "Bageshwar" },
    //     { value: "Bagpat", label: "Bagpat" },
    //     { value: "Bahraich", label: "Bahraich" },
    //     { value: "Baksa", label: "Baksa" },
    //     { value: "Balaghat", label: "Balaghat" },
    //     { value: "Balangir", label: "Balangir" },
    //     { value: "Balasore", label: "Balasore" },
    //     { value: "Ballia", label: "Ballia" },
    //     { value: "Balrampur", label: "Balrampur" },
    //     { value: "Banaskantha", label: "Banaskantha" },
    //     { value: "Banda", label: "Banda" },
    //     { value: "Bandipora", label: "Bandipora" },
    //     { value: "BangaloreRural", label: "Bangalore Rural" },
    //     { value: "BangaloreUrban", label: "Bangalore Urban" },
    //     { value: "Banka", label: "Banka" },
    //     { value: "Bankura", label: "Bankura" },
    //     { value: "Banswara", label: "Banswara" },
    //     { value: "Barabanki", label: "Barabanki" },
    //     { value: "Baramulla", label: "Baramulla" },
    //     { value: "Baran", label: "Baran" },
    //     { value: "Bardhaman", label: "Bardhaman" },
    //     { value: "Bareilly", label: "Bareilly" },
    //     { value: "Bargarh(Baragarh)", label: "Bargarh (Baragarh)" },
    //     { value: "Barmer", label: "Barmer" },
    //     { value: "Barnala", label: "Barnala" },
    //     { value: "Barpeta", label: "Barpeta" },
    //     { value: "Barwani", label: "Barwani" },
    //     { value: "Bastar", label: "Bastar" },
    //     { value: "Basti", label: "Basti" },
    //     { value: "Bathinda", label: "Bathinda" },
    //     { value: "Beed", label: "Beed" },
    //     { value: "Begusarai", label: "Begusarai" },
    //     { value: "Belgaum", label: "Belgaum" },
    //     { value: "Bellary", label: "Bellary" },
    //     { value: "Betul", label: "Betul" },
    //     { value: "Bhadrak", label: "Bhadrak" },
    //     { value: "Bhagalpur", label: "Bhagalpur" },
    //     { value: "Bhandara", label: "Bhandara" },
    //     { value: "Bharatpur", label: "Bharatpur" },
    //     { value: "Bharuch", label: "Bharuch" },
    //     { value: "Bhavnagar", label: "Bhavnagar" },
    //     { value: "Bhilwara", label: "Bhilwara" },
    //     { value: "Bhind", label: "Bhind" },
    //     { value: "Bhiwani", label: "Bhiwani" },
    //     { value: "Bhojpur", label: "Bhojpur" },
    //     { value: "Bhopal", label: "Bhopal" },
    //     { value: "Bidar", label: "Bidar" },
    //     { value: "Bijapur", label: "Bijapur" },
    //     { value: "Bijapur", label: "Bijapur" },
    //     { value: "Bijnor", label: "Bijnor" },
    //     { value: "Bikaner", label: "Bikaner" },
    //     { value: "Bilaspur", label: "Bilaspur" },
    //     { value: "Bilaspur", label: "Bilaspur" },
    //     { value: "Birbhum", label: "Birbhum" },
    //     { value: "Bishnupur", label: "Bishnupur" },
    //     { value: "Bokaro", label: "Bokaro" },
    //     { value: "Bongaigaon", label: "Bongaigaon" },
    //     { value: "Boudh(Bauda)", label: "Boudh (Bauda)" },
    //     { value: "Budaun", label: "Budaun" },
    //     { value: "Bulandshahr", label: "Bulandshahr" },
    //     { value: "Buldhana", label: "Buldhana" },
    //     { value: "Bundi", label: "Bundi" },
    //     { value: "Burhanpur", label: "Burhanpur" },
    //     { value: "Buxar", label: "Buxar" },
    //     { value: "Cachar", label: "Cachar" },
    //     { value: "CentralDelhi", label: "Central Delhi" },
    //     { value: "Chamarajnagar", label: "Chamarajnagar" },
    //     { value: "Chamba", label: "Chamba" },
    //     { value: "Chamoli", label: "Chamoli" },
    //     { value: "Champawat", label: "Champawat" },
    //     { value: "Champhai", label: "Champhai" },
    //     { value: "Chandauli", label: "Chandauli" },
    //     { value: "Chandel", label: "Chandel" },
    //     { value: "Chandigarh", label: "Chandigarh" },
    //     { value: "Chandrapur", label: "Chandrapur" },
    //     { value: "Changlang", label: "Changlang" },
    //     { value: "Chatra", label: "Chatra" },
    //     { value: "Chennai", label: "Chennai" },
    //     { value: "Chhatarpur", label: "Chhatarpur" },
    //     { value: "ChhatrapatiShahujiMaharajNagar", label: " Chhatrapati Shahuji Maharaj Nagar " },
    //     { value: "Chhindwara", label: "Chhindwara" },
    //     { value: "Chikkaballapur", label: "Chikkaballapur" },
    //     { value: "Chikkamagaluru", label: "Chikkamagaluru" },
    //     { value: "Chirang", label: "Chirang" },
    //     { value: "Chitradurga", label: "Chitradurga" },
    //     { value: "Chitrakoot", label: "Chitrakoot" },
    //     { value: "Chittoor", label: "Chittoor" },
    //     { value: "Chittorgarh", label: "Chittorgarh" },
    //     { value: "Churachandpur", label: "Churachandpur" },
    //     { value: "Churu", label: "Churu" },
    //     { value: "Coimbatore", label: "Coimbatore" },
    //     { value: "CoochBehar", label: "Cooch Behar" },
    //     { value: "Cuddalore", label: "Cuddalore" },
    //     { value: "Cuttack", label: "Cuttack" },
    //     { value: "DadraandNagarHaveli", label: " Dadra and Nagar Haveli " },
    //     { value: "Dahod", label: "Dahod" },
    //     { value: "DakshinDinajpur", label: "Dakshin Dinajpur" },
    //     { value: "DakshinaKannada", label: "Dakshina Kannada" },
    //     { value: "Daman", label: "Daman" },
    //     { value: "Damoh", label: "Damoh" },
    //     { value: "Dantewada", label: "Dantewada" },
    //     { value: "Darbhanga", label: "Darbhanga" },
    //     { value: "Darjeeling", label: "Darjeeling" },
    //     { value: "Darrang", label: "Darrang" },
    //     { value: "Datia", label: "Datia" },
    //     { value: "Dausa", label: "Dausa" },
    //     { value: "Davanagere", label: "Davanagere" },
    //     { value: "Debagarh(Deogarh)", label: "Debagarh (Deogarh)" },
    //     { value: "Dehradun", label: "Dehradun" },
    //     { value: "Deoghar", label: "Deoghar" },
    //     { value: "Deoria", label: "Deoria" },
    //     { value: "Dewas", label: "Dewas" },
    //     { value: "Dhalai", label: "Dhalai" },
    //     { value: "Dhamtari", label: "Dhamtari" },
    //     { value: "Dhanbad", label: "Dhanbad" },
    //     { value: "Dhar", label: "Dhar" },
    //     { value: "Dharmapuri", label: "Dharmapuri" },
    //     { value: "Dharwad", label: "Dharwad" },
    //     { value: "Dhemaji", label: "Dhemaji" },
    //     { value: "Dhenkanal", label: "Dhenkanal" },
    //     { value: "Dholpur", label: "Dholpur" },
    //     { value: "Dhubri", label: "Dhubri" },
    //     { value: "Dhule", label: "Dhule" },
    //     { value: "DibangValley", label: "Dibang Valley" },
    //     { value: "Dibrugarh", label: "Dibrugarh" },
    //     { value: "DimaHasao", label: "Dima Hasao" },
    //     { value: "Dimapur", label: "Dimapur" },
    //     { value: "Dindigul", label: "Dindigul" },
    //     { value: "Dindori", label: "Dindori" },
    //     { value: "Diu", label: "Diu" },
    //     { value: "Doda", label: "Doda" },
    //     { value: "Dumka", label: "Dumka" },
    //     { value: "Dungapur", label: "Dungapur" },
    //     { value: "Durg", label: "Durg" },
    //     { value: "EastChamparan", label: "East Champaran" },
    //     { value: "EastDelhi", label: "East Delhi" },
    //     { value: "EastGaroHills", label: "East Garo Hills" },
    //     { value: "EastKhasiHills", label: "East Khasi Hills" },
    //     { value: "EastSiang", label: "East Siang" },
    //     { value: "EastSikkim", label: "East Sikkim" },
    //     { value: "EastSinghbhum", label: "East Singhbhum" },
    //     { value: "Eluru", label: "Eluru" },
    //     { value: "Ernakulam", label: "Ernakulam" },
    //     { value: "Erode", label: "Erode" },
    //     { value: "Etah", label: "Etah" },
    //     { value: "Etawah", label: "Etawah" },
    //     { value: "Faizabad", label: "Faizabad" },
    //     { value: "Faridabad", label: "Faridabad" },
    //     { value: "Faridkot", label: "Faridkot" },
    //     { value: "Farrukhabad", label: "Farrukhabad" },
    //     { value: "Fatehabad", label: "Fatehabad" },
    //     { value: "FatehgarhSahib", label: "Fatehgarh Sahib" },
    //     { value: "Fatehpur", label: "Fatehpur" },
    //     { value: "Fazilka", label: "Fazilka" },
    //     { value: "Firozabad", label: "Firozabad" },
    //     { value: "Firozpur", label: "Firozpur" },
    //     { value: "Gadag", label: "Gadag" },
    //     { value: "Gadchiroli", label: "Gadchiroli" },
    //     { value: "Gajapati", label: "Gajapati" },
    //     { value: "Ganderbal", label: "Ganderbal" },
    //     { value: "Gandhinagar", label: "Gandhinagar" },
    //     { value: "Ganganagar", label: "Ganganagar" },
    //     { value: "Ganjam", label: "Ganjam" },
    //     { value: "Garhwa", label: "Garhwa" },
    //     { value: "GautamBuddhNagar", label: "Gautam Buddh Nagar" },
    //     { value: "Gaya", label: "Gaya" },
    //     { value: "Ghaziabad", label: "Ghaziabad" },
    //     { value: "Ghazipur", label: "Ghazipur" },
    //     { value: "Giridih", label: "Giridih" },
    //     { value: "Goalpara", label: "Goalpara" },
    //     { value: "Godda", label: "Godda" },
    //     { value: "Golaghat", label: "Golaghat" },
    //     { value: "Gonda", label: "Gonda" },
    //     { value: "Gondia", label: "Gondia" },
    //     { value: "Gopalganj", label: "Gopalganj" },
    //     { value: "Gorakhpur", label: "Gorakhpur" },
    //     { value: "Gulbarga", label: "Gulbarga" },
    //     { value: "Gumla", label: "Gumla" },
    //     { value: "Guna", label: "Guna" },
    //     { value: "Guntur", label: "Guntur" },
    //     { value: "Gurdaspur", label: "Gurdaspur" },
    //     { value: "Gurgaon", label: "Gurgaon" },
    //     { value: "Gwalior", label: "Gwalior" },
    //     { value: "Hailakandi", label: "Hailakandi" },
    //     { value: "Hamirpur", label: "Hamirpur" },
    //     { value: "Hamirpur", label: "Hamirpur" },
    //     { value: "Hanumangarh", label: "Hanumangarh" },
    //     { value: "Harda", label: "Harda" },
    //     { value: "Hardoi", label: "Hardoi" },
    //     { value: "Haridwar", label: "Haridwar" },
    //     { value: "Hassan", label: "Hassan" },
    //     { value: "Haveridistrict", label: "Haveri district" },
    //     { value: "Hazaribag", label: "Hazaribag" },
    //     { value: "Hingoli", label: "Hingoli" },
    //     { value: "Hissar", label: "Hissar" },
    //     { value: "Hooghly", label: "Hooghly" },
    //     { value: "Hoshangabad", label: "Hoshangabad" },
    //     { value: "Hoshiarpur", label: "Hoshiarpur" },
    //     { value: "Howrah", label: "Howrah" },
    //     { value: "Hyderabad", label: "Hyderabad" },
    //     { value: "Hyderabad", label: "Hyderabad" },
    //     { value: "Idukki", label: "Idukki" },
    //     { value: "ImphalEast", label: "Imphal East" },
    //     { value: "ImphalWest", label: "Imphal West" },
    //     { value: "Indore", label: "Indore" },
    //     { value: "Jabalpur", label: "Jabalpur" },
    //     { value: "Jagatsinghpur", label: "Jagatsinghpur" },
    //     { value: "JaintiaHills", label: "Jaintia Hills" },
    //     { value: "Jaipur", label: "Jaipur" },
    //     { value: "Jaisalmer", label: "Jaisalmer" },
    //     { value: "Jajpur", label: "Jajpur" },
    //     { value: "Jalandhar", label: "Jalandhar" },
    //     { value: "Jalaun", label: "Jalaun" },
    //     { value: "Jalgaon", label: "Jalgaon" },
    //     { value: "Jalna", label: "Jalna" },
    //     { value: "Jalore", label: "Jalore" },
    //     { value: "Jalpaiguri", label: "Jalpaiguri" },
    //     { value: "Jammu", label: "Jammu" },
    //     { value: "Jamnagar", label: "Jamnagar" },
    //     { value: "Jamtara", label: "Jamtara" },
    //     { value: "Jamui", label: "Jamui" },
    //     { value: "Janjgir-Champa", label: "Janjgir-Champa" },
    //     { value: "Jashpur", label: "Jashpur" },
    //     { value: "Jaunpurdistrict", label: "Jaunpur district" },
    //     { value: "Jehanabad", label: "Jehanabad" },
    //     { value: "Jhabua", label: "Jhabua" },
    //     { value: "Jhajjar", label: "Jhajjar" },
    //     { value: "Jhalawar", label: "Jhalawar" },
    //     { value: "Jhansi", label: "Jhansi" },
    //     { value: "Jharsuguda", label: "Jharsuguda" },
    //     { value: "Jhunjhunu", label: "Jhunjhunu" },
    //     { value: "Jind", label: "Jind" },
    //     { value: "Jodhpur", label: "Jodhpur" },
    //     { value: "Jorhat", label: "Jorhat" },
    //     { value: "Junagadh", label: "Junagadh" },
    //     { value: "JyotibaPhuleNagar", label: "Jyotiba Phule Nagar" },
    //     { value: "Kabirdham(formerlyKawardha)", label: " Kabirdham (formerly Kawardha) " },
    //     { value: "Kadapa", label: "Kadapa" },
    //     { value: "Kaimur", label: "Kaimur" },
    //     { value: "Kaithal", label: "Kaithal" },
    //     { value: "Kakinada", label: "Kakinada" },
    //     { value: "Kalahandi", label: "Kalahandi" },
    //     { value: "Kamrup", label: "Kamrup" },
    //     { value: "KamrupMetropolitan", label: "Kamrup Metropolitan" },
    //     { value: "Kanchipuram", label: "Kanchipuram" },
    //     { value: "Kandhamal", label: "Kandhamal" },
    //     { value: "Kangra", label: "Kangra" },
    //     { value: "Kanker", label: "Kanker" },
    //     { value: "Kannauj", label: "Kannauj" },
    //     { value: "Kannur", label: "Kannur" },
    //     { value: "Kanpur", label: "Kanpur" },
    //     { value: "KanshiRamNagar", label: "Kanshi Ram Nagar" },
    //     { value: "Kanyakumari", label: "Kanyakumari" },
    //     { value: "Kapurthala", label: "Kapurthala" },
    //     { value: "Karaikal", label: "Karaikal" },
    //     { value: "Karauli", label: "Karauli" },
    //     { value: "KarbiAnglong", label: "Karbi Anglong" },
    //     { value: "Kargil", label: "Kargil" },
    //     { value: "Karimganj", label: "Karimganj" },
    //     { value: "Karimnagar", label: "Karimnagar" },
    //     { value: "Karnal", label: "Karnal" },
    //     { value: "Karur", label: "Karur" },
    //     { value: "Kasaragod", label: "Kasaragod" },
    //     { value: "Kathua", label: "Kathua" },
    //     { value: "Katihar", label: "Katihar" },
    //     { value: "Katni", label: "Katni" },
    //     { value: "Kaushambi", label: "Kaushambi" },
    //     { value: "Kendrapara", label: "Kendrapara" },
    //     { value: "Kendujhar(Keonjhar)", label: " Kendujhar (Keonjhar) " },
    //     { value: "Khagaria", label: "Khagaria" },
    //     { value: "Khammam", label: "Khammam" },
    //     { value: "Khandwa(EastNimar)", label: "Khandwa (East Nimar)" },
    //     { value: "Khargone(WestNimar)", label: " Khargone (West Nimar) " },
    //     { value: "Kheda", label: "Kheda" },
    //     { value: "Khordha", label: "Khordha" },
    //     { value: "Khowai", label: "Khowai" },
    //     { value: "Khunti", label: "Khunti" },
    //     { value: "Kinnaur", label: "Kinnaur" },
    //     { value: "Kishanganj", label: "Kishanganj" },
    //     { value: "Kishtwar", label: "Kishtwar" },
    //     { value: "Kodagu", label: "Kodagu" },
    //     { value: "Koderma", label: "Koderma" },
    //     { value: "Kohima", label: "Kohima" },
    //     { value: "Kokrajhar", label: "Kokrajhar" },
    //     { value: "Kolar", label: "Kolar" },
    //     { value: "Kolasib", label: "Kolasib" },
    //     { value: "Kolhapur", label: "Kolhapur" },
    //     { value: "Kolkata", label: "Kolkata" },
    //     { value: "Kollam", label: "Kollam" },
    //     { value: "Koppal", label: "Koppal" },
    //     { value: "Koraput", label: "Koraput" },
    //     { value: "Korba", label: "Korba" },
    //     { value: "Koriya", label: "Koriya" },
    //     { value: "Kota", label: "Kota" },
    //     { value: "Kottayam", label: "Kottayam" },
    //     { value: "Kozhikode", label: "Kozhikode" },
    //     { value: "Krishna", label: "Krishna" },
    //     { value: "Kulgam", label: "Kulgam" },
    //     { value: "Kullu", label: "Kullu" },
    //     { value: "Kupwara", label: "Kupwara" },
    //     { value: "Kurnool", label: "Kurnool" },
    //     { value: "Kurukshetra", label: "Kurukshetra" },
    //     { value: "KurungKumey", label: "Kurung Kumey" },
    //     { value: "Kushinagar", label: "Kushinagar" },
    //     { value: "Kutch", label: "Kutch" },
    //     { value: "LahaulandSpiti", label: "Lahaul and Spiti" },
    //     { value: "Lakhimpur", label: "Lakhimpur" },
    //     { value: "LakhimpurKheri", label: "Lakhimpur Kheri" },
    //     { value: "Lakhisarai", label: "Lakhisarai" },
    //     { value: "Lalitpur", label: "Lalitpur" },
    //     { value: "Latehar", label: "Latehar" },
    //     { value: "Latur", label: "Latur" },
    //     { value: "Lawngtlai", label: "Lawngtlai" },
    //     { value: "Leh", label: "Leh" },
    //     { value: "Lohardaga", label: "Lohardaga" },
    //     { value: "Lohit", label: "Lohit" },
    //     { value: "LowerDibangValley", label: "Lower Dibang Valley" },
    //     { value: "LowerSubansiri", label: "Lower Subansiri" },
    //     { value: "Lucknow", label: "Lucknow" },
    //     { value: "Ludhiana", label: "Ludhiana" },
    //     { value: "Lunglei", label: "Lunglei" },
    //     { value: "Madhepura", label: "Madhepura" },
    //     { value: "Madhubani", label: "Madhubani" },
    //     { value: "Madurai", label: "Madurai" },
    //     { value: "MahamayaNagar", label: "Mahamaya Nagar" },
    //     { value: "Maharajganj", label: "Maharajganj" },
    //     { value: "Mahasamund", label: "Mahasamund" },
    //     { value: "Mahbubnagar", label: "Mahbubnagar" },
    //     { value: "Mahe", label: "Mahe" },
    //     { value: "Mahendragarh", label: "Mahendragarh" },
    //     { value: "Mahoba", label: "Mahoba" },
    //     { value: "Mainpuri", label: "Mainpuri" },
    //     { value: "Malappuram", label: "Malappuram" },
    //     { value: "Maldah", label: "Maldah" },
    //     { value: "Malkangiri", label: "Malkangiri" },
    //     { value: "Mamit", label: "Mamit" },
    //     { value: "Mandi", label: "Mandi" },
    //     { value: "Mandla", label: "Mandla" },
    //     { value: "Mandsaur", label: "Mandsaur" },
    //     { value: "Mandya", label: "Mandya" },
    //     { value: "Mansa", label: "Mansa" },
    //     { value: "Marigaon", label: "Marigaon" },
    //     { value: "Mathura", label: "Mathura" },
    //     { value: "Mau", label: "Mau" },
    //     { value: "Mayurbhanj", label: "Mayurbhanj" },
    //     { value: "Medak", label: "Medak" },
    //     { value: "Meerut", label: "Meerut" },
    //     { value: "Mehsana", label: "Mehsana" },
    //     { value: "Mewat", label: "Mewat" },
    //     { value: "Mirzapur", label: "Mirzapur" },
    //     { value: "Moga", label: "Moga" },
    //     { value: "Mokokchung", label: "Mokokchung" },
    //     { value: "Mon", label: "Mon" },
    //     { value: "Moradabad", label: "Moradabad" },
    //     { value: "Morena", label: "Morena" },
    //     { value: "MumbaiCity", label: "Mumbai City" },
    //     { value: "Mumbaisuburban", label: "Mumbai suburban" },
    //     { value: "Munger", label: "Munger" },
    //     { value: "Murshidabad", label: "Murshidabad" },
    //     { value: "Muzaffarnagar", label: "Muzaffarnagar" },
    //     { value: "Muzaffarpur", label: "Muzaffarpur" },
    //     { value: "Mysore", label: "Mysore" },
    //     { value: "Nabarangpur", label: "Nabarangpur" },
    //     { value: "Nadia", label: "Nadia" },
    //     { value: "Nagaon", label: "Nagaon" },
    //     { value: "Nagapattinam", label: "Nagapattinam" },
    //     { value: "Nagaur", label: "Nagaur" },
    //     { value: "Nagpur", label: "Nagpur" },
    //     { value: "Nainital", label: "Nainital" },
    //     { value: "Nalanda", label: "Nalanda" },
    //     { value: "Nalbari", label: "Nalbari" },
    //     { value: "Nalgonda", label: "Nalgonda" },
    //     { value: "Namakkal", label: "Namakkal" },
    //     { value: "Nanded", label: "Nanded" },
    //     { value: "Nandurbar", label: "Nandurbar" },
    //     { value: "Narayanpur", label: "Narayanpur" },
    //     { value: "Narmada", label: "Narmada" },
    //     { value: "Narsinghpur", label: "Narsinghpur" },
    //     { value: "Nashik", label: "Nashik" },
    //     { value: "Navsari", label: "Navsari" },
    //     { value: "Nawada", label: "Nawada" },
    //     { value: "Nawanshahr", label: "Nawanshahr" },
    //     { value: "Nayagarh", label: "Nayagarh" },
    //     { value: "Neemuch", label: "Neemuch" },
    //     { value: "Nellore", label: "Nellore" },
    //     { value: "NewDelhi", label: "New Delhi" },
    //     { value: "Nilgiris", label: "Nilgiris" },
    //     { value: "Nizamabad", label: "Nizamabad" },
    //     { value: "Noida", label: "Noida" },
    //     { value: "North24Parganas", label: "North 24 Parganas" },
    //     { value: "NorthDelhi", label: "North Delhi" },
    //     { value: "NorthEastDelhi", label: "North East Delhi" },
    //     { value: "NorthGoa", label: "North Goa" },
    //     { value: "NorthSikkim", label: "North Sikkim" },
    //     { value: "NorthTripura", label: "North Tripura" },
    //     { value: "NorthWestDelhi", label: "North West Delhi" },
    //     { value: "Nuapada", label: "Nuapada" },
    //     { value: "Ongole", label: "Ongole" },
    //     { value: "Osmanabad", label: "Osmanabad" },
    //     { value: "Pakur", label: "Pakur" },
    //     { value: "Palakkad", label: "Palakkad" },
    //     { value: "Palamu", label: "Palamu" },
    //     { value: "Pali", label: "Pali" },
    //     { value: "Palwal", label: "Palwal" },
    //     { value: "Panchkula", label: "Panchkula" },
    //     { value: "Panchmahal", label: "Panchmahal" },
    //     { value: "PanchsheelNagardistrict(Hapur)", label: " Panchsheel Nagar district (Hapur) " },
    //     { value: "Panipat", label: "Panipat" },
    //     { value: "Panna", label: "Panna" },
    //     { value: "PapumPare", label: "Papum Pare" },
    //     { value: "Parbhani", label: "Parbhani" },
    //     { value: "PaschimMedinipur", label: "Paschim Medinipur" },
    //     { value: "Patan", label: "Patan" },
    //     { value: "Pathanamthitta", label: "Pathanamthitta" },
    //     { value: "Pathankot", label: "Pathankot" },
    //     { value: "Patiala", label: "Patiala" },
    //     { value: "Patna", label: "Patna" },
    //     { value: "PauriGarhwal", label: "Pauri Garhwal" },
    //     { value: "Perambalur", label: "Perambalur" },
    //     { value: "Phek", label: "Phek" },
    //     { value: "Pilibhit", label: "Pilibhit" },
    //     { value: "Pithoragarh", label: "Pithoragarh" },
    //     { value: "Pondicherry", label: "Pondicherry" },
    //     { value: "Poonch", label: "Poonch" },
    //     { value: "Porbandar", label: "Porbandar" },
    //     { value: "Pratapgarh", label: "Pratapgarh" },
    //     { value: "Pratapgarh", label: "Pratapgarh" },
    //     { value: "Pudukkottai", label: "Pudukkottai" },
    //     { value: "Pulwama", label: "Pulwama" },
    //     { value: "Pune", label: "Pune" },
    //     { value: "PurbaMedinipur", label: "Purba Medinipur" },
    //     { value: "Puri", label: "Puri" },
    //     { value: "Purnia", label: "Purnia" },
    //     { value: "Purulia", label: "Purulia" },
    //     { value: "Raebareli", label: "Raebareli" },
    //     { value: "Raichur", label: "Raichur" },
    //     { value: "Raigad", label: "Raigad" },
    //     { value: "Raigarh", label: "Raigarh" },
    //     { value: "Raipur", label: "Raipur" },
    //     { value: "Raisen", label: "Raisen" },
    //     { value: "Rajauri", label: "Rajauri" },
    //     { value: "Rajgarh", label: "Rajgarh" },
    //     { value: "Rajkot", label: "Rajkot" },
    //     { value: "Rajnandgaon", label: "Rajnandgaon" },
    //     { value: "Rajsamand", label: "Rajsamand" },
    //     { value: "RamabaiNagar(KanpurDehat)", label: " Ramabai Nagar (Kanpur Dehat) " },
    //     { value: "Ramanagara", label: "Ramanagara" },
    //     { value: "Ramanathapuram", label: "Ramanathapuram" },
    //     { value: "Ramban", label: "Ramban" },
    //     { value: "Ramgarh", label: "Ramgarh" },
    //     { value: "Rampur", label: "Rampur" },
    //     { value: "Ranchi", label: "Ranchi" },
    //     { value: "Ratlam", label: "Ratlam" },
    //     { value: "Ratnagiri", label: "Ratnagiri" },
    //     { value: "Rayagada", label: "Rayagada" },
    //     { value: "Reasi", label: "Reasi" },
    //     { value: "Rewa", label: "Rewa" },
    //     { value: "Rewari", label: "Rewari" },
    //     { value: "RiBhoi", label: "Ri Bhoi" },
    //     { value: "Rohtak", label: "Rohtak" },
    //     { value: "Rohtas", label: "Rohtas" },
    //     { value: "Rudraprayag", label: "Rudraprayag" },
    //     { value: "Rupnagar", label: "Rupnagar" },
    //     { value: "Sabarkantha", label: "Sabarkantha" },
    //     { value: "Sagar", label: "Sagar" },
    //     { value: "Saharanpur", label: "Saharanpur" },
    //     { value: "Saharsa", label: "Saharsa" },
    //     { value: "Sahibganj", label: "Sahibganj" },
    //     { value: "Saiha", label: "Saiha" },
    //     { value: "Salem", label: "Salem" },
    //     { value: "Samastipur", label: "Samastipur" },
    //     { value: "Samba", label: "Samba" },
    //     { value: "Sambalpur", label: "Sambalpur" },
    //     { value: "Sangli", label: "Sangli" },
    //     { value: "Sangrur", label: "Sangrur" },
    //     { value: "SantKabirNagar", label: "Sant Kabir Nagar" },
    //     { value: "SantRavidasNagar", label: "Sant Ravidas Nagar" },
    //     { value: "Saran", label: "Saran" },
    //     { value: "Satara", label: "Satara" },
    //     { value: "Satna", label: "Satna" },
    //     { value: "SawaiMadhopur", label: "Sawai Madhopur" },
    //     { value: "Sehore", label: "Sehore" },
    //     { value: "Senapati", label: "Senapati" },
    //     { value: "Seoni", label: "Seoni" },
    //     { value: "SeraikelaKharsawan", label: "Seraikela Kharsawan" },
    //     { value: "Serchhip", label: "Serchhip" },
    //     { value: "Shahdol", label: "Shahdol" },
    //     { value: "Shahjahanpur", label: "Shahjahanpur" },
    //     { value: "Shajapur", label: "Shajapur" },
    //     { value: "Shamli", label: "Shamli" },
    //     { value: "Sheikhpura", label: "Sheikhpura" },
    //     { value: "Sheohar", label: "Sheohar" },
    //     { value: "Sheopur", label: "Sheopur" },
    //     { value: "Shimla", label: "Shimla" },
    //     { value: "Shimoga", label: "Shimoga" },
    //     { value: "Shivpuri", label: "Shivpuri" },
    //     { value: "Shopian", label: "Shopian" },
    //     { value: "Shravasti", label: "Shravasti" },
    //     { value: "Sibsagar", label: "Sibsagar" },
    //     { value: "Siddharthnagar", label: "Siddharthnagar" },
    //     { value: "Sidhi", label: "Sidhi" },
    //     { value: "Sikar", label: "Sikar" },
    //     { value: "Simdega", label: "Simdega" },
    //     { value: "Sindhudurg", label: "Sindhudurg" },
    //     { value: "Singrauli", label: "Singrauli" },
    //     { value: "Sirmaur", label: "Sirmaur" },
    //     { value: "Sirohi", label: "Sirohi" },
    //     { value: "Sirsa", label: "Sirsa" },
    //     { value: "Sitamarhi", label: "Sitamarhi" },
    //     { value: "Sitapur", label: "Sitapur" },
    //     { value: "Sivaganga", label: "Sivaganga" },
    //     { value: "Siwan", label: "Siwan" },
    //     { value: "Solan", label: "Solan" },
    //     { value: "Solapur", label: "Solapur" },
    //     { value: "Sonbhadra", label: "Sonbhadra" },
    //     { value: "Sonipat", label: "Sonipat" },
    //     { value: "Sonitpur", label: "Sonitpur" },
    //     { value: "South24Parganas", label: "South 24 Parganas" },
    //     { value: "SouthDelhi", label: "South Delhi" },
    //     { value: "SouthGaroHills", label: "South Garo Hills" },
    //     { value: "SouthGoa", label: "South Goa" },
    //     { value: "SouthSikkim", label: "South Sikkim" },
    //     { value: "SouthTripura", label: "South Tripura" },
    //     { value: "SouthWestDelhi", label: "South West Delhi" },
    //     { value: "SriMuktsarSahib", label: "Sri Muktsar Sahib" },
    //     { value: "Srikakulam", label: "Srikakulam" },
    //     { value: "Srinagar", label: "Srinagar" },
    //     { value: "Subarnapur(Sonepur)", label: " Subarnapur (Sonepur) " },
    //     { value: "Sultanpur", label: "Sultanpur" },
    //     { value: "Sundergarh", label: "Sundergarh" },
    //     { value: "Supaul", label: "Supaul" },
    //     { value: "Surat", label: "Surat" },
    //     { value: "Surendranagar", label: "Surendranagar" },
    //     { value: "Surguja", label: "Surguja" },
    //     { value: "Tamenglong", label: "Tamenglong" },
    //     { value: "TarnTaran", label: "Tarn Taran" },
    //     { value: "Tawang", label: "Tawang" },
    //     { value: "TehriGarhwal", label: "Tehri Garhwal" },
    //     { value: "Thane", label: "Thane" },
    //     { value: "Thanjavur", label: "Thanjavur" },
    //     { value: "TheDangs", label: "The Dangs" },
    //     { value: "Theni", label: "Theni" },
    //     { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
    //     { value: "Thoothukudi", label: "Thoothukudi" },
    //     { value: "Thoubal", label: "Thoubal" },
    //     { value: "Thrissur", label: "Thrissur" },
    //     { value: "Tikamgarh", label: "Tikamgarh" },
    //     { value: "Tinsukia", label: "Tinsukia" },
    //     { value: "Tirap", label: "Tirap" },
    //     { value: "Tiruchirappalli", label: "Tiruchirappalli" },
    //     { value: "Tirunelveli", label: "Tirunelveli" },
    //     { value: "Tirupur", label: "Tirupur" },
    //     { value: "Tiruvallur", label: "Tiruvallur" },
    //     { value: "Tiruvannamalai", label: "Tiruvannamalai" },
    //     { value: "Tiruvarur", label: "Tiruvarur" },
    //     { value: "Tonk", label: "Tonk" },
    //     { value: "Tuensang", label: "Tuensang" },
    //     { value: "Tumkur", label: "Tumkur" },
    //     { value: "Udaipur", label: "Udaipur" },
    //     { value: "Udalguri", label: "Udalguri" },
    //     { value: "UdhamSinghNagar", label: "Udham Singh Nagar" },
    //     { value: "Udhampur", label: "Udhampur" },
    //     { value: "Udupi", label: "Udupi" },
    //     { value: "Ujjain", label: "Ujjain" },
    //     { value: "Ukhrul", label: "Ukhrul" },
    //     { value: "Umaria", label: "Umaria" },
    //     { value: "Una", label: "Una" },
    //     { value: "Unnao", label: "Unnao" },
    //     { value: "UpperSiang", label: "Upper Siang" },
    //     { value: "UpperSubansiri", label: "Upper Subansiri" },
    //     { value: "UttarDinajpur", label: "Uttar Dinajpur" },
    //     { value: "UttaraKannada", label: "Uttara Kannada" },
    //     { value: "Uttarkashi", label: "Uttarkashi" },
    //     { value: "Vadodara", label: "Vadodara" },
    //     { value: "Vaishali", label: "Vaishali" },
    //     { value: "Valsad", label: "Valsad" },
    //     { value: "Varanasi", label: "Varanasi" },
    //     { value: "Vellore", label: "Vellore" },
    //     { value: "Vidisha", label: "Vidisha" },
    //     { value: "Viluppuram", label: "Viluppuram" },
    //     { value: "Virudhunagar", label: "Virudhunagar" },
    //     { value: "Visakhapatnam", label: "Visakhapatnam" },
    //     { value: "Vizianagaram", label: "Vizianagaram" },
    //     { value: "Vyara", label: "Vyara" },
    //     { value: "Warangal", label: "Warangal" },
    //     { value: "Wardha", label: "Wardha" },
    //     { value: "Washim", label: "Washim" },
    //     { value: "Wayanad", label: "Wayanad" },
    //     { value: "WestChamparan", label: "West Champaran" },
    //     { value: "WestDelhi", label: "West Delhi" },
    //     { value: "WestGaroHills", label: "West Garo Hills" },
    //     { value: "WestKameng", label: "West Kameng" },
    //     { value: "WestKhasiHills", label: "West Khasi Hills" },
    //     { value: "WestSiang", label: "West Siang" },
    //     { value: "WestSikkim", label: "West Sikkim" },
    //     { value: "WestSinghbhum", label: "West Singhbhum" },
    //     { value: "WestTripura", label: "West Tripura" },
    //     { value: "Wokha", label: "Wokha" },
    //     { value: "Yadgir", label: "Yadgir" },
    //     { value: "YamunaNagar", label: "Yamuna Nagar" },
    //     { value: "Yanam", label: "Yanam" },
    //     { value: "Yavatmal", label: "Yavatmal" },
    //     { value: "Zunheboto", label: "Zunheboto" },
    // ];

    return (
        <>
        <ToastContainer/>
        <div className='job-form'>

            <div className="modal fade" id="JobFormForProfessionals" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title fs-5" id="exampleModalLabel">Apply Now</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form ref={form} id='career-form' method='POST' >
                                <div className="mb-3">
                                    <input type="text"
                                        className='form-control'
                                        name="Name"
                                        placeholder="Enter Name *"
                                        autoFocus
                                        onChange={(e) => { SetName(e.target.value) }}
                                        required />
                                </div>
                                <div className="mb-3">
                                    <input type="text"
                                        name="Mobile"
                                        className='form-control'
                                        placeholder="Enter Mobile *"
                                        autoFocus
                                        onChange={(e) => { SetMobile(e.target.value) }}
                                        required />
                                </div>

                                <div className="mb-3">
                                    <input style={{ textTransform: 'lowercase' }} type="email"
                                        name="Email"
                                        className='form-control'
                                        placeholder="Enter Email *"
                                        autoFocus
                                        onChange={(e) => { SetEmail(e.target.value) }}
                                        required />
                                </div>

                                <div className="mb-3">
                                    <input type="file"
                                        className='form-control'
                                        accept='application/pdf'
                                        autoFocus
                                        onChange={(e) => {
                                            setPdfUpload(e.target.files[0]);
                                        }}
                                        required />
                                </div>
                                <input type="text"
                                    style={{ display: "none" }}
                                    name="CV"
                                    value={pdfURL}
                                />
                                  <input type="text"
                                    style={{ display: "none" }}
                                    name="careerType"
                                    value="Professional"
                                />

                                <select aria-label="Default select example"
                                    onChange={(e) => { SetPosition(e.target.value) }}
                                    className="form-select mb-3"
                                    name='Job_Position'>
                                    <option hidden>Select Job Position</option>
                                    {careerOFP.map(careers => (
                                        <option value={careers.Job_Title}>{careers.Job_Title}</option>
                                    ))}
                                </select>

                                {careerOFP.map(careers => (
                                    <select aria-label="Default select example"
                                        onChange={(e) => { SetLocation(e.target.value) }}
                                        className="form-select mb-3"
                                        name='Job_Location'>

                                        <option hidden value="Select Preferred Location">Select Preferred Location</option>
                                        {careers.Location.map(datas => (
                                            <option value={datas.value}>{datas.label}</option>
                                        ))}

                                    </select>
                                ))}
                                <textarea
                                    className="form-control mb-3"
                                    placeholder='Cover Letter *'
                                    name="Job_Letter"
                                    rows="4"
                                    onChange={(e) => { SetCoverLetter(e.target.value) }}></textarea>

                                <button type="submit" className="btn w-100" value="Submit" onClick={submit}>Submit</button>
                            </form>
                        </div>
                        <div className="modal-footer">
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}

export default JobFormForProfessionals
