import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const download = () => {
    window.open(
      "https://firebasestorage.googleapis.com/v0/b/wise-finserv-private-wealth.appspot.com/o/disclosure.Pdf?alt=media&token=65dbea6d-6f38-4f34-9d7b-f239b970c4ca",
      "_blank"
    );
  };

  const information = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "About Us",
      url: "/about-us",
    },
    {
      title: "What We Offer",
      url: "/what-we-offer",
    },
    {
      title: "Investment Products",
      url: "/investment-products",
    },
    {
      title: "Why Us",
      url: "/why-us",
    },
    {
      title: "Blog",
      url: "/blog",
    },
    {
      title: "Career",
      url: "/career",
    },
    {
      title: "Contact",
      url: "/contact-us",
    },
  ];

  const usefulLinks = [
    {
      title: "Advisor Login",
      url: "https://www.wisefinserv.com/login/advisor-login.php",
    },
    {
      title: "Admin Login",
      url: "https://www.wisefinserv.com/login/admin-login.php",
    },
    {
      title: "Branch Login",
      url: "https://www.wisefinserv.com/login/branch-login.php",
    },
    {
      title: "NPS Login",
      url: "https://digital-onboard.finnate.app/iciciprupension/2",
    },
  ];

  const contactInfo = [
    {
      icon: (
        <svg
          height="512"
          viewBox="0 0 64 64"
          width="512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Call">
            <path d="m52.5 42.85-2.83-2.82a5.9873 5.9873 0 0 0 -8.48 0l-1.27 1.26a1.9573 1.9573 0 0 1 -1.83.54c-2.1-.47-5.49-1.92-9.74-6.17-4.26-4.26-5.71-7.65-6.18-9.75a1.9573 1.9573 0 0 1 .54-1.83l1.27-1.27a6.01768 6.01768 0 0 0 0-8.48l-2.83-2.83a6.01143 6.01143 0 0 0 -8.48 0l-.87.87a10.64659 10.64659 0 0 0 -2.89 9.89c1.49 6.71 6.41 15.28 11.98 20.85 5.57 5.56 14.14 10.49 20.84 11.98a10.96437 10.96437 0 0 0 2.34.26 10.68538 10.68538 0 0 0 7.56-3.15l.87-.87a6.01143 6.01143 0 0 0 0-8.48z" />
          </g>
        </svg>
      ),
      title: "0120-4114832",
      url: "tel:01204114832",
    },
    {
      icon: (
        <svg viewBox="0 -77 512 512" xmlns="http://www.w3.org/2000/svg">
          <path d="m490.753906 0h-469.507812l234.753906 234.453125zm0 0" />
          <path d="m0 21.179688v316.445312l158.425781-158.222656zm0 0" />
          <path d="m256 276.851562-76.347656-76.25-158.40625 158.203126h469.507812l-158.40625-158.203126zm0 0" />
          <path d="m353.574219 179.402344 158.425781 158.222656v-316.445312zm0 0" />
        </svg>
      ),
      title: "contactus@wisefinserv.com",
      url: "mailto:contactus@wisefinserv.com",
    },
    {
      icon: (
        <svg
          id="Layer_1"
          enable-background="new 0 0 512 512"
          height="512"
          viewBox="0 0 512 512"
          width="512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m256 0c-105.6 0-191.5 85.9-191.5 191.5 0 34.4 16.2 77.8 49.4 132.7 27.6 45.6 61.8 90.9 91.9 130.8 13.9 18.4 27 35.8 37.1 50.2 3 4.3 7.9 6.8 13.1 6.8s10.1-2.5 13.1-6.8c10.1-14.4 23.2-31.8 37.1-50.2 30.1-39.9 64.2-85.2 91.9-130.8 33.2-54.9 49.4-98.3 49.4-132.7 0-105.6-85.9-191.5-191.5-191.5zm0 280c-48.8 0-88.5-39.7-88.5-88.5s39.7-88.5 88.5-88.5 88.5 39.7 88.5 88.5-39.7 88.5-88.5 88.5z" />
        </svg>
      ),
      title: "G-5, Sector - 3 Noida, Uttar Pradesh - 201301",
      url: "https://www.google.com/maps?cid=15060986139053528968",
    },
  ];

  const follow = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
        >
          <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
        </svg>
      ),
      url: "https://www.facebook.com/wisefinserv",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
        >
          <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
        </svg>
      ),
      url: "https://twitter.com/wisefinserv?lang=en",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
        </svg>
      ),
      url: "https://www.linkedin.com/company/wise-finserv-pvt--ltd-?trk=nav_account_sub_nav_company_adming",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 576 512"
        >
          <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
        </svg>
      ),
      url: "https://www.youtube.com/channel/UCeF5wd8wQIFiqMnvmMhYEUQ",
    },
  ];

  return (
    <div>
      <footer>
        <div className="footer-body">
          <div className="footer-about">
            <h2>Wise FinServ Private Wealth</h2>
            <p>
              We offer simple investment options tailored to clients' needs,
              serving HNI and UHNI. Market-leading qualifications assure clients
              that their future is in capable hands. Our comprehensive services
              cover personal finance globally. Our innovative approach and
              strategic partnerships ensure exceptional client experiences.
              Services include investment advisory, portfolio management, tax
              planning, insurance, and retirement planning.
            </p>
          </div>

          <div className="footer-links">
            <h4>Information</h4>
            <div className="footer-links-content">
              {information.map((item) => (
                <Link to={item.url}>
                  <svg
                    id="Layer_1"
                    enable-background="new 0 0 100 100"
                    height="512"
                    viewBox="0 0 100 100"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" />
                    <path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" />
                    <path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" />
                    <path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" />
                  </svg>
                  &emsp;
                  {item.title}
                </Link>
              ))}
            </div>
          </div>

          <div class="footer-links">
            <h4>useful links</h4>
            <div class="footer-links-content">
              {usefulLinks.map((item) => (
                <a href={item.url}>
                  <svg
                    viewBox="-42 0 512 512.002"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m210.351562 246.632812c33.882813 0 63.222657-12.152343 87.195313-36.128906 23.972656-23.972656 36.125-53.304687 36.125-87.191406 0-33.875-12.152344-63.210938-36.128906-87.191406-23.976563-23.96875-53.3125-36.121094-87.191407-36.121094-33.886718 0-63.21875 12.152344-87.191406 36.125s-36.128906 53.308594-36.128906 87.1875c0 33.886719 12.15625 63.222656 36.132812 87.195312 23.976563 23.96875 53.3125 36.125 87.1875 36.125zm0 0" />
                    <path d="m426.128906 393.703125c-.691406-9.976563-2.089844-20.859375-4.148437-32.351563-2.078125-11.578124-4.753907-22.523437-7.957031-32.527343-3.308594-10.339844-7.808594-20.550781-13.371094-30.335938-5.773438-10.15625-12.554688-19-20.164063-26.277343-7.957031-7.613282-17.699219-13.734376-28.964843-18.199219-11.226563-4.441407-23.667969-6.691407-36.976563-6.691407-5.226563 0-10.28125 2.144532-20.042969 8.5-6.007812 3.917969-13.035156 8.449219-20.878906 13.460938-6.707031 4.273438-15.792969 8.277344-27.015625 11.902344-10.949219 3.542968-22.066406 5.339844-33.039063 5.339844-10.972656 0-22.085937-1.796876-33.046874-5.339844-11.210938-3.621094-20.296876-7.625-26.996094-11.898438-7.769532-4.964844-14.800782-9.496094-20.898438-13.46875-9.75-6.355468-14.808594-8.5-20.035156-8.5-13.3125 0-25.75 2.253906-36.972656 6.699219-11.257813 4.457031-21.003906 10.578125-28.96875 18.199219-7.605469 7.28125-14.390625 16.121094-20.15625 26.273437-5.558594 9.785157-10.058594 19.992188-13.371094 30.339844-3.199219 10.003906-5.875 20.945313-7.953125 32.523437-2.058594 11.476563-3.457031 22.363282-4.148437 32.363282-.679688 9.796875-1.023438 19.964844-1.023438 30.234375 0 26.726562 8.496094 48.363281 25.25 64.320312 16.546875 15.746094 38.441406 23.734375 65.066406 23.734375h246.53125c26.625 0 48.511719-7.984375 65.0625-23.734375 16.757813-15.945312 25.253906-37.585937 25.253906-64.324219-.003906-10.316406-.351562-20.492187-1.035156-30.242187zm0 0" />
                  </svg>
                  &emsp;
                  {item.title}
                </a>
              ))}
            </div>
          </div>

          <div className="footer-contact">
            <h4>Contact info</h4>
            <div className="footer-contact-body">
              {contactInfo.map((item) => (
                <div className="footer-contact-content">
                  {item.icon}
                  <a href={item.url}>{item.title}</a>
                </div>
              ))}
            </div>
            <Link style={{ marginTop: "10%" }} to="/investor-grievance">
              Investor Grievance
            </Link>
            <div className="footer-follow">
              <div className="footer-follow-top">
                {follow.map((item) => (
                  <a target="_blank" href={item.url} rel="noreferrer">
                    {item.icon}
                  </a>
                ))}
              </div>

              <div className="footer-follow-bottom">
                <a
                  className="download"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.mobileappz.wisefinserv&hl=en_IN"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z" />
                  </svg>
                  download for android
                </a>

                <a
                  className="download ios"
                  target="_blank"
                  href="https://apps.apple.com/qa/app/wise-finserv/id1229613459"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 384 512"
                  >
                    <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
                  </svg>
                  download for ios
                </a>
              </div>
            </div>
          </div>
          <div className="footer-map">
            <iframe
              src="https://www.google.com/maps/d/u/0/embed?mid=1TYsPN7rZ6GZnUIfUzbMrp-18rJznlTk&ehbc=2E312F&noprof=1"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <div className="footer-copyright">
          <p>
            © Copyright {new Date().getFullYear()}. All Rights Reserved by Wise
            Finserv Private Wealth.
          </p>

          <div className="footer-copyright-content">
            <Link to={"/privacy-policy"}>Privacy Policy</Link>
            <Link to={"/disclaimer"}>Disclaimer</Link>
            <a href="" onClick={download}>
              Disclosure
            </a>
          </div>
        </div>
        <p className="last">
          Mutual fund investments are subject to market risks. Please read the
          scheme information and other related documents carefully before
          investing. Past performance is not indicative of future returns.
          Please consider your specific investment requirements before choosing
          a fund, or designing a portfolio that suits your needs. Wise
          FinServ.(ARN code 106923 ) makes no warranties or representations,
          express or implied, on products offered through the platform. It
          accepts no liability for any damages or losses, however caused, in
          connection with the use of, or on the reliance of its product or
          related services. Terms and conditions of the website are applicable.
          We distribute insurance products through our group company, Wise
          Finserv Insurance Marketing Firm Pvt Ltd. Apple and the Apple logo are
          trademarks of Apple Inc., registered in the U.S. and other
          countries.App Store is a service mark of Apple Inc., registered in the
          U.S. and other countries. The Android robot is reproduced or modified
          from work created and shared by Google and used according to terms
          described in the Creative Commons 3.0 Attribution License.
        </p>

        <div className="amfi">
          <div className="amfi-card">
            <h6>Registered with AMFI:</h6>
            <p>Yes</p>
          </div>
          <div className="amfi-card">
            <h6>Date of Initial Registration:</h6>
            <p>21/11/2015</p>
          </div>
          <div className="amfi-card">
            <h6>AMFI Registration Number:</h6>
            <p>106923</p>
          </div>
          <div className="amfi-card">
            <h6>Current Validity of ARN:</h6>
            <p>21/11/2027</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
